<template>
  <transition name="modal-fade">
    <div ref="modal" class="modal-backdrop" @click="clickOutside">
      <div class="modal" role="dialog">
        <div class="btn-close" @click="close">
          <md-icon>close</md-icon>
        </div>

        <div class="title-wrap">
          <h4 class="md-title">
            {{ promotion.title }}
            <span
              class="icon"
              @click="$router.push(`/promotions/${promotion.id}`)"
            >
              <md-icon> edit </md-icon>
            </span>
          </h4>
        </div>

        <div class="description-wrap">
          <p class="md-body-1">{{ promotion.description }}</p>
        </div>

        <div class="md-layout">
          <div class="images-wrap md-layout-item md-large-size-100 md-layout">
            <div class="md-layout-item md-large-size-20">
              <img :src="promotion.image" alt="Image" />
            </div>
          </div>

          <div class="md-layout-item md-large-size-50">
            <md-field class="info-field">
              <p class="info">
                <span class="md-body-2">Дата начала:</span>
                {{ getDate(promotion.from_date) }}
              </p>
            </md-field>
          </div>

          <div class="md-layout-item md-large-size-50">
            <md-field class="info-field">
              <p class="info">
                <span class="md-body-2">Дата окончания:</span>
                {{ getDate(promotion.to_date) }}
              </p>
            </md-field>
          </div>

          <div class="md-layout-item md-large-size-50">
            <md-field class="info-field">
              <p class="info">
                <span class="md-body-2">Процент скидки:</span>
                {{ promotion.percent }}
              </p>
            </md-field>
          </div>

          <div class="md-layout-item md-large-size-50">
            <md-field class="info-field">
              <p class="info">
                <span class="md-body-2">Статус:</span>
                {{ promotion.is_active ? "Активна" : "Не активна" }}
              </p>
            </md-field>
          </div>
        </div>

        <PromotionProductsTable :products="promotion.promotion_product" />
      </div>
    </div>
  </transition>
</template>

<script>
import PromotionProductsTable from "@/components/Tables/PromotionProductsTable";

import { formatISODate } from "@/helpers/formatDate";

export default {
  components: {
    PromotionProductsTable,
  },

  props: {
    promotion: {
      type: Object,
      required: true,
    },
  },

  methods: {
    clickOutside(e) {
      if (e.target.classList.contains("modal-backdrop")) {
        this.$emit("close");
      }
    },

    close() {
      this.$emit("close");
    },

    getDate(isoDate) {
      return formatISODate(isoDate);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10001;

  .modal {
    position: relative;
    height: auto;
    padding: 20px;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 5px;
    background: #fff;
    min-width: 700px;
    max-width: 900px;
  }

  .btn-close {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    font-size: 20px;
    color: #c4c4c4;
  }
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.title-wrap {
  display: flex;
  justify-content: center;

  .md-title {
    position: relative;

    .icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(calc(100% + 10px), -50%);
      cursor: pointer;
      display: flex;
    }
  }
}

.description-wrap {
  margin-top: 15px;
  text-align: center;
}

.images-wrap {
  margin: 20px 0;
  justify-content: center;
}

.info {
  width: max-content;
}
</style>
