<template>
  <div class="products-table">
    <div v-if="products.length" class="position-table">
      <!-- eslint-disable-next-line vue/no-mutating-props -->
      <md-table v-model="products">
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="ID товара" class="custom-field">
            <span>{{ item.product_id }}</span>
          </md-table-cell>

          <md-table-cell md-label="Название товара" class="custom-field">
            <span>{{ item.product.name }}</span>
          </md-table-cell>

          <md-table-cell md-label="Бренд" class="custom-field">
            <span>{{ item.product.brand.name }}</span>
          </md-table-cell>

          <md-table-cell md-label="Цена за шт." class="custom-field">
            <span>{{ item.product.price }}</span>
          </md-table-cell>

          <md-table-cell md-label="Кол-во" class="custom-field">
            <span>{{ item.product.quantity }}</span>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>
    <div v-else class="my-empty empty">Пусто</div>
  </div>
</template>

<script>
export default {
  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.products-table {
  margin-top: 20px;

  &::v-deep {
    .md-table-row {
      cursor: auto;
    }
  }
}
</style>
