<template>
  <div>
    <div
      v-if="promotions.data && promotions.data.length"
      class="position-table"
    >
      <md-table
        v-model="promotions.data"
        :md-sort-fn="customSort"
        :md-sort.sync="customSortBy"
        :md-sort-order.sync="customSortOrder"
      >
        <md-table-row
          slot="md-table-row"
          slot-scope="{ item }"
          @click="openModal(item.id)"
        >
          <md-table-cell md-label="ID" md-sort-by="id" class="custom-field">
            <span>{{ item.id }}</span>
          </md-table-cell>

          <md-table-cell
            md-label="Название"
            md-sort-by="title"
            class="custom-field"
          >
            <span>{{ item.title }}</span>
          </md-table-cell>

          <md-table-cell md-label="Изображение" class="custom-field">
            <img class="cover-image" :src="item.image" alt="Image" />
          </md-table-cell>

          <md-table-cell
            md-label="Дата начала"
            md-sort-by="from_date"
            class="custom-field"
          >
            <span>{{ getDate(item.from_date) }}</span>
          </md-table-cell>

          <md-table-cell
            md-label="Дата окончания"
            md-sort-by="to_date"
            class="custom-field"
          >
            <span>{{ getDate(item.to_date) }}</span>
          </md-table-cell>

          <md-table-cell
            md-label="Процент скидки"
            md-sort-by="percent"
            class="custom-field"
          >
            <span>{{ item.percent }}</span>
          </md-table-cell>

          <md-table-cell
            md-label="Статус"
            md-sort-by="is_active"
            class="custom-field"
          >
            <span>{{ item.is_active ? "Активна" : "Не активна" }}</span>
          </md-table-cell>

          <md-table-cell>
            <div class="edit-and-delete-block">
              <md-button
                class="md-just-icon md-simple md-primary"
                @click.stop="$router.push(`/promotions/${item.id}`)"
              >
                <md-icon>edit</md-icon>
                <md-tooltip md-direction="top">Редактировать</md-tooltip>
              </md-button>

              <md-button
                class="md-just-icon md-simple md-danger"
                @click.stop="selectedId = item.id"
              >
                <md-icon>delete</md-icon>
                <md-tooltip md-direction="top">Удалить</md-tooltip>
              </md-button>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>

      <div v-if="promotions.meta.last_page > 1" class="pagination">
        <md-button
          class="md-primary"
          :disabled="promotions.meta.current_page === 1"
          @click="$emit('prev')"
        >
          <md-icon>navigate_before</md-icon>
        </md-button>
        <div class="pages">
          <span>{{ promotions.meta.current_page }}</span>
          /
          <span>{{ promotions.meta.last_page }}</span>
        </div>
        <md-button
          class="md-primary"
          :disabled="promotions.meta.current_page === promotions.meta.last_page"
          @click="$emit('next')"
        >
          <md-icon>navigate_next</md-icon>
        </md-button>
      </div>
    </div>
    <div v-else class="my-empty empty">Пусто</div>

    <ModalPromotion
      v-if="showPromotionModal"
      :promotion="promotion"
      @close="showPromotionModal = false"
    />

    <ModalConfirm
      v-if="selectedId"
      text="Удалить акцию?"
      @confirm="deletePromotion"
      @close="selectedId = null"
    />
  </div>
</template>

<script>
import ModalConfirm from "@/components/Modals/Confirm";
import ModalPromotion from "@/components/Modals/Promotion";

import { mapState, mapActions } from "vuex";

import { formatISODate } from "@/helpers/formatDate";

export default {
  components: {
    ModalConfirm,
    ModalPromotion,
  },

  data() {
    return {
      customSortBy: "id",
      customSortOrder: "asc",
      showPromotionModal: false,
      selectedId: null,
    };
  },

  computed: {
    ...mapState({
      promotion: (state) => state.promotions.promotion,
      promotions: (state) => state.promotions.promotions,
    }),
  },

  methods: {
    ...mapActions("promotions", ["getPromotion", "removePromotion"]),

    customSort(value) {
      this.$emit("sort", {
        sortBy: this.customSortBy,
        sortOrder: this.customSortOrder,
      });

      return value;
    },

    async deletePromotion() {
      this.$store.commit("SET_SHOW_LOADER", true);
      let success = await this.removePromotion(this.selectedId);
      if (success) {
        this.$emit("sort", {
          sortBy: this.customSortBy,
          sortOrder: this.customSortOrder,
        });

        this.selectedId = null;
      }
      this.$store.commit("SET_SHOW_LOADER", false);
    },

    getDate(isoDate) {
      return formatISODate(isoDate);
    },

    async openModal(promotion_id) {
      this.$store.commit("SET_SHOW_LOADER", true);
      let success = await this.getPromotion(promotion_id);
      if (success) {
        this.showPromotionModal = true;
      }
      this.$store.commit("SET_SHOW_LOADER", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;

  .pages {
    display: flex;
    align-items: center;
    padding: 0 15px;
  }
}

::v-deep {
  .md-table-sortable-icon {
    left: unset !important;
    right: 8px !important;
  }

  .md-table-row {
    cursor: pointer;
  }
}

.cover-image {
  max-width: 100%;
  max-height: 100px;
  object-fit: cover;
}

.custom-field {
  max-width: 150px;

  &::v-deep {
    .md-table-cell-container {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>